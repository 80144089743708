import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import ToastMessage, {ToastData} from "../toastmessage/toastMessage";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {withRouter} from "react-router";
import {isAuthErrorResp, logout} from "../../lib/sessionutils";
import HistoryProps from "../../types/history";
import {getDomain} from "../../lib/net";
import {Divider} from "@material-ui/core";

const axios = require("axios");

const useStyles = makeStyles(theme => ({
    explanation: {
        paddingTop: "40px",
    },
}))

export default withRouter(AdvancedInstallSSHKey as any)

function AdvancedInstallSSHKey(props: { history: HistoryProps }) {
    const classes = useStyles();

    const [caKey, setCaKey] = React.useState<string | null>(null);
    const [principals, setPrincipals] = React.useState<Array<string> | null>(null);
    const [toastData, setToastData] = useState<ToastData>({message: "", type: "error"});

    useEffect(() => {
        axios
            .get(`${getDomain()}/api/v1/get_ca_key`)
            .then((resp: any) => {
                if (resp.data.status) {
                    setCaKey(resp.data.body);
                } else {
                    if (isAuthErrorResp(resp)) {
                        logout(props.history.push)
                    } else {
                        setToastData({message: resp.data.body, type: "error"});
                    }
                }
            })
            .catch(() => window.location.reload());
        axios
            .get(`${getDomain()}/api/v1/advanced_get_principals`)
            .then((resp: any) => {
                if (resp.data.status) {
                    setPrincipals(resp.data.body)
                } else {
                    if (isAuthErrorResp(resp)) {
                        logout(props.history.push)
                    } else {
                        setToastData({message: resp.data.body, type: "error"});
                    }
                }
            })
            .catch(() => window.location.reload());
    }, []);

    return (
        <>
            <Typography variant="h6" gutterBottom>
                Deploy one key to all your servers
            </Typography>
            <Typography variant="body1" align="center">
                On each server that you wish to manage with Cascara:
            </Typography>
            <SyntaxHighlighter language="shell">
                {caKey !== null && principals !== null
                    ? 'mkdir /etc/ssh/auth_principals/\n' +
                    'echo "' +
                    caKey +
                    '" > /etc/ssh/ca.pub\n' +
                    'echo "TrustedUserCAKeys /etc/ssh/ca.pub" >> /etc/ssh/sshd_config\n' +
                    'echo "AuthorizedPrincipalsFile /etc/ssh/auth_principals/%u" >> /etc/ssh/sshd_config\n' +
                    'service ssh restart'
                    : "Loading..."}
            </SyntaxHighlighter>
            {principals !== null && principals.map((p, i) => (
                <>
                    <Typography variant="body1" align="center">
                        On each server in {JSON.stringify(p)}:
                    </Typography>
                    <SyntaxHighlighter language="shell">
                        { 'echo "' +
                            p +
                            '" > /etc/ssh/auth_principals/root'
                        }
                    </SyntaxHighlighter>
                </>
            ))}
            <Typography variant="body1" align="center" className={classes.explanation}>
                This one key is the certificate that will be used to grant SSH access. Our software will automatically
                provision short-lived SSH keys that are trusted by this certificate. This means that you will never
                again
                have to manage SSH keys!
            </Typography>

            <ToastMessage type={toastData.type} isOpen={toastData.message !== ""} message={toastData.message}/>
        </>
    );
}