import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SimpleInstallSSHKey from "./SimpleInstallSSHKey";
import DownloadCSSH from "./DownloadCSSH";
import OrgModeSwitcher from "./OrgModeSwitcher";
import AdvancedInstallSSHKey from "./AdvancedInstallSSHKey";
import {withRouter} from "react-router";
import HistoryProps from "../../types/history";
import SimpleManageUsers from "./SimpleManageUsers";
import AdvancedManageUsers from "./AdvancedManageUsers";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: "relative"
    },
    layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3)
        }
    },
    stepper: {
        padding: theme.spacing(3, 0, 5)
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
}));

const steps = ["Add Users", "Deploy One Key", "Download and SSH!"];

function getStepContent(history: HistoryProps, step: number) {
    switch (step) {
        case 0:
            return <OrgModeSwitcher history={history} simple_component={<SimpleManageUsers/>} advanced_component={<AdvancedManageUsers />}/>;
        case 1:
            return <OrgModeSwitcher history={history} simple_component={<SimpleInstallSSHKey/>} advanced_component={<AdvancedInstallSSHKey />}/>;
        case 2:
            return <DownloadCSSH/>;
        default:
            throw new Error("Unknown step");
    }
}

export default withRouter(Tutorial);

function Tutorial(props: { history: HistoryProps }) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState<number>(0);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
        window.scrollTo(0, 0);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
        window.scrollTo(0, 0);
    };

    return (
        <React.Fragment>
            <CssBaseline/>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center">
                        Get Started
                    </Typography>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom align="center">
                                    Done
                                </Typography>
                                <Typography variant="subtitle1" align="center">
                                    Get back to what matters, confident in your SSH security.
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {getStepContent(props.history, activeStep)}
                                <div className={classes.buttons}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} className={classes.button}>
                                            Back
                                        </Button>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === steps.length - 1 ? "Done" : "Next"}
                                    </Button>
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Paper>
            </main>
        </React.Fragment>
    );
}
