import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import ToastMessage, {ToastData} from "../toastmessage/toastMessage";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {isAuthErrorResp, logout} from "../../lib/sessionutils";
import {withRouter} from "react-router";
import HistoryProps from "../../types/history";
import {getDomain} from "../../lib/net";
const axios = require("axios");

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(1, 0)
  },
  total: {
    fontWeight: 700
  },
  title: {
    marginTop: theme.spacing(2)
  }
}));

export default withRouter(DownloadCSSH)
function DownloadCSSH(props: {history: HistoryProps}) {
  const classes = useStyles();

  const [orgId, setOrgId] = useState<number | null>(null);
  const [sshUser, setSshUser] = useState<string>("root");
  const [toastData, setToastData] = useState<ToastData>({message: "", type: "error"});

  useEffect(() => {
    axios
      .get(`${getDomain()}/api/v1/get_org_info`)
      .then((resp: any) => {
        if (resp.data.status) {
          setOrgId(resp.data.body.id);
        } else {
          if (isAuthErrorResp(resp)) {
            logout(props.history.push)
          } else {
            setToastData({message: resp.data.body, type: "error"});
          }
        }
      })
      .catch(() => window.location.reload());
    axios
      .get(`${getDomain()}/api/v1/get_ssh_user`)
      .then((resp: any) => {
        if (resp.data.status) {
          if (resp.data.body) {
            setSshUser(resp.data.body);
          }
        } else {
          if (isAuthErrorResp(resp)) {
            logout(props.history.push)
          } else {
            setToastData({message: resp.data.body, type: "error"});
          }
        }
      })
      .catch(() => window.location.reload());
  }, []);

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Download and SSH!
      </Typography>
      <SyntaxHighlighter language="shell">
        {orgId === null
          ? "Loading..."
          : "curl -L https://binaries.cascarasecurity.com/cssh-" +
            orgId +
            " > cssh\n" +
            "chmod +x cssh\n" +
            "sudo mv cssh /usr/local/bin/cssh\n" +
            "cssh " +
            sshUser +
            "@server\n"}
      </SyntaxHighlighter>
        <Typography variant="body1" gutterBottom align="center">
            That is really all there is to it!
        </Typography>

        <ToastMessage type={toastData.type} isOpen={toastData.message !== ""} message={toastData.message} />
    </>
  );
}
