import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import ToastMessage, {ToastData} from "../toastmessage/toastMessage";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HelpIcon from "@material-ui/icons/Help";
import {isValidEmail} from "../../lib/validation";
import {isAuthErrorResp, logout} from "../../lib/sessionutils";
import {withRouter} from "react-router";
import HistoryProps from "../../types/history";
import {getDomain} from "../../lib/net";

const axios = require("axios");

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0)
    },
    total: {
        fontWeight: 700
    },
    title: {
        marginTop: theme.spacing(2)
    },
    addEmailButton: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default withRouter(SimpleManageUsers)

function SimpleManageUsers(props: { history: HistoryProps }) {
    const classes = useStyles();
    const [principal, setPrincipal] = React.useState<string>("");
    const [users, setUsers] = React.useState<Array<string>>([""]);
    const [toastData, setToastData] = useState<ToastData>({message: "", type: "error"});
    const [successCount, setSuccessCount] = useState<number>(0);

    useEffect(() => {
        axios.get(`${getDomain()}/api/v1/list_emails`)
            .then((resp: any) => {
                if (resp.data.status) {
                    setUsers([...resp.data.body.filter((user: any) => !user.is_domain && user !== "").map((user: any) => user.email), ""])
                } else {
                    if (isAuthErrorResp(resp)) {
                        logout(props.history.push)
                    } else {
                        setToastData({message: resp.data.body, type: "error"});
                    }
                }
            })
            .catch(() => window.location.reload());
        axios.get(`${getDomain()}/api/v1/get_principal`)
            .then((resp: any) => {
                if (resp.data.status) {
                    if (resp.data.body) {
                        setPrincipal(resp.data.body)
                    }
                } else {
                    if (isAuthErrorResp(resp)) {
                        logout(props.history.push)
                    } else {
                        setToastData({message: resp.data.body, type: "error"});
                    }
                }
            })
            .catch(() => window.location.reload());
    }, []);

    if (successCount >= 2) {
        setSuccessCount(prev => prev >= 2 ? prev - 2 : prev)
        setToastData({message: "Saved!", type: "success"})
    }

    return (
        <>
            <Typography variant="h6" gutterBottom>
                Manage users
            </Typography>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="principal"
                label="SSH User"
                name="principal"
                autoFocus
                value={principal}
                onChange={event => setPrincipal(event.target.value)}
                helperText="The SSH user used on all servers (root by default)"
            />

            <Typography variant="subtitle1" gutterBottom>
                Cascara Users {"  "}
                <Tooltip
                    arrow
                    title="Configure the GSuite users that will be given SSH access via logging in with their Google account">
                    <HelpIcon/>
                </Tooltip>

            </Typography>

            <List dense={false}>
                {users.map((user, idx) => (
                    <ListItem key={user + idx}>
                        <ListItemAvatar>
                            <Avatar>
                                <EmailIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required={idx === 0}
                            fullWidth
                            id="email"
                            label={isValidEmail(user) || user === "" ? "Email Address" : "Please enter a valid email address"}
                            name="email"
                            autoFocus
                            error={user !== "" && !isValidEmail(user)}
                            value={user}
                            onChange={event =>
                                setUsers(
                                    users.map((u, i) => (i === idx ? event.target.value : u))
                                )
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="Delete"
                                onClick={() => setUsers(users.filter((_, i) => i !== idx))}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <Button
                className={classes.addEmailButton}
                onClick={() => setUsers([...users, ""])}
            >
                <AddCircleIcon/>
            </Button>
            <Divider/>
            <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => {
                    let body = {
                        emails: users
                    };
                    axios
                        .post(`${getDomain()}/api/v1/set_emails`, body)
                        .then(function (response: any) {
                            if (response.data.status) {
                                setSuccessCount(successCount => successCount + 1)
                            } else {
                                if (isAuthErrorResp(response)) {
                                    logout(props.history.push)
                                } else {
                                    setToastData({message: response.data.body, type: "error"});
                                }
                            }
                        })
                        .catch(function (error: any) {
                            console.log(error);
                            window.location.reload();
                        });
                    axios
                        .post(`${getDomain()}/api/v1/set_principal`, {principal})
                        .then((response: any) => {
                            if (response.data.status) {
                                setSuccessCount(successCount => successCount + 1)
                            } else {
                                if (isAuthErrorResp(response)) {
                                    logout(props.history.push)
                                } else {
                                    setToastData({message: response.data.body, type: "error"});
                                }
                            }
                        })
                        .catch((error: any) => {
                            console.log(error);
                            window.location.reload();
                        });
                }}
            >
                Save
            </Button>
            <ToastMessage type={toastData.type} isOpen={toastData.message !== ""} message={toastData.message}
                          closed={() => setToastData((prev) => ({message: "", type: prev.type}))}/>
        </>
    );
}
