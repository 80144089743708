import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {Redirect, withRouter} from "react-router-dom";
import {isAuthErrorResp, isLoggedIn, logout} from "../../lib/sessionutils";
import ToastMessage, {ToastData} from "../toastmessage/toastMessage";
import HistoryProps from "../../types/history";
import {getDomain} from "../../lib/net";

var QRCode = require('qrcode.react');
const axios = require("axios");

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default withRouter(SignUp)
function SignUp(props: {history: HistoryProps}) {
  const classes = useStyles();
  const [company, setCompany] = useState<string>("");
  const [fname, setFname] = useState<string>("");
  const [lname, setLname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [provisioningUrl, setProvisioningUrl] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [totpCode, setTotpCode] = useState<string>("");
  const [productEmails, setProductEmails] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [toastData, setToastData] = useState<ToastData>({message: "", type: "error"});

  useEffect(() => {
    isLoggedIn(() => setLoggedIn(true), () => setLoggedIn(false));
  }, []);

  if (loggedIn === true) {
    return <Redirect to="/console" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="orgName"
                label="Company Name"
                name="orgName"
                autoFocus
                value={company}
                onChange={event => setCompany(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                value={fname}
                onChange={event => setFname(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                value={lname}
                onChange={event => setLname(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={email}
                onChange={event => {
                  setEmail(event.target.value);
                  axios
                    .get(`${getDomain()}/api/v1/generate_totp_provisioning_url?email=${event.target.value}`)
                    .then(function(response: any) {
                      if (response.data.status) {
                        setProvisioningUrl(response.data.body)
                      } else {
                        if (isAuthErrorResp(response)) {
                          logout(props.history.push)
                        } else {
                          setToastData({message: response.data.body, type: "error"});
                        }
                      }
                    })
                    .catch(function(error: any) {
                      console.log(error);
                      window.location.reload();
                    });

                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
            </Grid>
            <Typography variant="body2" gutterBottom align="center" style={{width: '100%'}}>
              Scan the below QR code with Google Authenticator
            </Typography>
            <QRCode value={provisioningUrl} style={{margin: 'auto', paddingBottom: '8px'}} />
            <TextField
              variant="outlined"
              required
              fullWidth
              id="totpCode"
              label="2FA Code"
              name="totpCode"
              value={totpCode}
              autoComplete='disabled'
              onChange={event => setTotpCode(event.target.value)}
            />
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="allowExtraEmails"
                    color="primary"
                    checked={productEmails}
                    onChange={event =>
                      setProductEmails(!productEmails)
                    }
                  />
                }
                label="I want to receive product updates via email."
              />
            </Grid>
          </Grid>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              let body = {
                org_name: company,
                fullname: fname + " " + lname,
                email: email,
                password: password,
                product_emails: productEmails,
                totpCode: totpCode,
                provisioningUrl: provisioningUrl,
              };
              axios
                .post(`${getDomain()}/api/v1/signup`, body)
                .then(function(response: any) {
                  if (response.data.status) {
                    setLoggedIn(true);
                  } else {
                    if (isAuthErrorResp(response)) {
                      logout(props.history.push)
                    } else {
                      setToastData({message: response.data.body, type: "error"});
                    }
                  }
                })
                .catch(function(error: any) {
                  console.log(error);
                  window.location.reload();
                });
            }}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/signin" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
        <ToastMessage type={toastData.type} isOpen={toastData.message !== ""} message={toastData.message} />
      </div>
    </Container>
  );
}
