import {getDomain} from "./net";

const axios = require("axios");

let isLoggedIn = (trueCb: () => void, falseCb: () => void) => {
  axios
    .get(`${getDomain()}/api/v1/is_authed`)
    .then((resp: any) => {
      if (resp.data.status) {
        trueCb();
      } else {
        falseCb();
      }
    })
    .catch(falseCb);
};

let logout = (navigateCallback: (path: string) => void) => {
  axios.get(`${getDomain()}/api/v1/logout`)
    .then((resp: any) => {
      navigateCallback('/signin');
    })
    .catch((err: any) => {
      console.log(err)
      navigateCallback('/signin');
    })
}

let isAuthErrorMessage = (message: string): boolean => message === "Invalid Session. Please log in."
let isAuthErrorResp = (resp: any): boolean => isAuthErrorMessage(resp.data.body)

export {
  isLoggedIn,
  isAuthErrorMessage,
  isAuthErrorResp,
  logout
};
