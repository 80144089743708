let isProduction = () => process.env.NODE_ENV === "production"

let getDomain = () => {
  if (isProduction()) {
    return "https://keys.cascarasecurity.com"
  } else {
    return "http://localhost:5000"
  }
}

export {
  isProduction,
  getDomain
}