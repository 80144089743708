import React, { useEffect, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import { amber, green } from "@material-ui/core/colors";

export interface ToastData{
  message: string
  type: ToastType
}

type ToastType = "error" | "success"

type ToastMessageProps = {
  message: string;
  isOpen: boolean;
  type: ToastType;
  closed?: () => void
  autoClose?: boolean;
};

let typeToIcon = (type: ToastType, classes: any) => {
  if (type === "error") {
    return (<ErrorIcon className={(classes.icon, classes.iconVariant)} />);
  }
  if (type === "success") {
    return (<CheckIcon className={(classes.icon, classes.iconVariant)} />);
  }
}

export default function ToastMessage(props: ToastMessageProps) {
  const classes = useStyles();

  const { isOpen, message } = props;

  const [hasBeenClosed, setHasBeenClosed] = useState<boolean>(false);

  useEffect(() => {
    setHasBeenClosed(false);
  }, [isOpen, message]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      open={isOpen && !hasBeenClosed}
      autoHideDuration={props.autoClose === false ? null : 6000}
      onClose={() => {
        setHasBeenClosed(true)
        if (props.closed) {
          props.closed()
        }
      }}
    >
      <SnackbarContent
        className={classes[props.type]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            {typeToIcon(props.type, classes)}
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              setHasBeenClosed(true)
              if (props.closed) {
                props.closed()
              }
            }}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
}

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

