import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {Redirect, withRouter} from "react-router-dom";
import {isAuthErrorResp, isLoggedIn, logout} from "../../lib/sessionutils";
import ToastMessage, {ToastData} from "../toastmessage/toastMessage";
import HistoryProps from "../../types/history";
import {getDomain} from "../../lib/net";
const axios = require("axios");

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default withRouter(SignIn as any)
function SignIn(props: {history: HistoryProps}) {
  const classes = useStyles();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [totpCode, setTotpCode] = useState<string>("");
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [toastData, setToastData] = useState<ToastData>({message: "", type: "error"});

  useEffect(() => {
    isLoggedIn(() => setLoggedIn(true), () => setLoggedIn(false));
  }, []);

  if (loggedIn === true) {
    return <Redirect to="/console" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="totpCode"
            label="2FA Code"
            name="totpCode"
            value={totpCode}
            autoComplete='disabled'
            onChange={event => setTotpCode(event.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              let body = {
                email: email,
                password: password,
                totpCode: totpCode,
              };
              axios
                .post(`${getDomain()}/api/v1/login`, body)
                .then(function(response: any) {
                  if (response.data.status) {
                    setLoggedIn(true);
                  } else {
                    if (isAuthErrorResp(response)) {
                      logout(props.history.push)
                    } else {
                      setToastData({message: response.data.body, type: "error"});
                    }
                  }
                })
                .catch(function(error: any) {
                  console.log(error);
                  window.location.reload();
                });
            }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/reset_password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
        <ToastMessage type={toastData.type} isOpen={toastData.message !== ""} message={toastData.message} />
      </div>
    </Container>
  );
}
