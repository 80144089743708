import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel, makeStyles} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {default as ToastMessage, ToastData} from "../toastmessage/toastMessage";
import {getDomain} from "../../lib/net";
import {isAuthErrorResp, logout} from "../../lib/sessionutils";
import {RouteComponentProps, withRouter} from "react-router";
import HistoryProps from "../../types/history";
import Button from "@material-ui/core/Button";

const axios = require("axios");

const useStyles = makeStyles(theme => ({
    padding: {
        padding: "10px"
    },
    flex: {
        display: "flex",
    },
}));

type Expiration = {
    'number': number
    'unit': 's' | 'h' | 'm' | 'd'
}

type Props = RouteComponentProps<{}> & {
    initExpiration: Expiration
    getEndpoint: string
    setEndpoint: string
    description: string
    history: HistoryProps
}

export default withRouter(ExpirationPicker)
function ExpirationPicker(props: Props) {
    const classes = useStyles()

    const [expiration, setExpiration] = useState<Expiration>(props.initExpiration);
    const [toastData, setToastData] = useState<ToastData>({message: "", type: "error"});
    useEffect(() => {
        axios
            .get(`${getDomain()}${props.getEndpoint}`)
            .then((resp: any) => {
                if (resp.data.status) {
                    if (resp.data.body) {
                        setExpiration(resp.data.body);
                    }
                } else {
                    if (isAuthErrorResp(resp)) {
                        logout(props.history.push)
                    } else {
                        setToastData({message: resp.data.body, type: "error"});
                    }
                }
            })
            .catch(() => window.location.reload());
    }, [props.getEndpoint, props.setEndpoint]);

    const inputLabel = React.useRef<any>(null);
    const [labelWidth, setLabelWidth] = React.useState(40);
    React.useEffect(() => {
        inputLabel && inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <>
            <Grid justify="center" spacing={10} className={classes.flex}>
                <TextField
                    label="Length"
                    value={expiration.number}
                    onChange={(event: any) => setExpiration((e) => ({...e, number: event.target.value} as any))}
                    type="number"
                    margin="normal"
                    variant="outlined"
                    className={classes.padding}
                />
                <FormControl variant="outlined" margin="normal" className={classes.padding}>
                    <InputLabel ref={inputLabel} htmlFor="key-unit">Unit</InputLabel>
                    <Select
                        value={expiration.unit}
                        onChange={(event: any) => setExpiration((e) => ({...e, unit: event.target.value}) as any)}
                        input={<OutlinedInput labelWidth={labelWidth} name="key-unit" id="key-unit" />}
                    >
                        <MenuItem value="s">Seconds</MenuItem>
                        <MenuItem value="m">Minutes</MenuItem>
                        <MenuItem value="h">Hours</MenuItem>
                        <MenuItem value="d">Days</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                    axios
                        .post(`${getDomain()}${props.setEndpoint}`, expiration)
                        .then(function(response: any) {
                            if (response.data.status) {
                                setToastData({message: "Saved!", type: "success"})
                            } else {
                                if (isAuthErrorResp(response)) {
                                    logout(props.history.push)
                                } else {
                                    setToastData({message: response.data.body, type: "error"});
                                }
                            }
                        })
                        .catch(function(error: any) {
                            console.log(error);
                            window.location.reload();
                        });
                }}
            >
                Save
            </Button>
            <Typography variant="body1" align="center">
                {props.description}
            </Typography>
            <ToastMessage type={toastData.type} isOpen={toastData.message !== ""} message={toastData.message} />
        </>

    )
}