import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import EmailIcon from "@material-ui/icons/Email";
import FolderIcon from "@material-ui/icons/Folder";
import DashboardIcon from "@material-ui/icons/Dashboard";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {withRouter} from "react-router";
import ToastMessage, {ToastData} from "../toastmessage/toastMessage";
import Banner from "../banner/banner";
import {getDomain} from "../../lib/net";
import HistoryProps from "../../types/history";
import {isAuthErrorResp, logout} from "../../lib/sessionutils";
import clsx from 'clsx';
const axios = require("axios");

const drawerWidth = 240;

const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1,
        display:"flex",
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        height: "100vh"
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        height: "100vh"
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },

}));

type BannerType = "VerificationBanner"
type Props = {
    history: HistoryProps,
}

const UnauthedEndpoints = ['/signin', '/signup', '/verify_email', '/reset_password']

export default withRouter(MenuBar as any)
function MenuBar(props: any) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const [intervalId, setIntervalId] = React.useState<any>(null);
    const [banners, setBanners] = React.useState<Array<BannerType>>([]);
    const [toastData, setToastData] = useState<ToastData>({message: "", type: "error"});
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
    const open = Boolean(anchorEl);

    function isAuthed() {
        for (var i in UnauthedEndpoints) {
            if (props.history.location.pathname.startsWith(UnauthedEndpoints[i])) {
                return false
            }
        }
        return true
    }

    function updateBanners() {
        if (!isAuthed()) {
            return
        }
        axios
      .get(`${getDomain()}/api/v1/get_banners`)
      .then((resp: any) => {
        if (resp.data.status) {
          setBanners(resp.data.body)
        } else {
          if (isAuthErrorResp(resp)) {
            logout(props.history.push)
          } else {
            setToastData({message: resp.data.body, type: "error"});
          }
        }
      })
      .catch(() => window.location.reload());
    }

    useEffect(() => {
        setIntervalId(setInterval(updateBanners, 2000));

        return () => {
            // Run at unmount
            clearInterval(intervalId)
        }
    }, []);

    function renderBanner(banner: BannerType) {
        if (banner === "VerificationBanner") {
            return (
                <Banner
                    style={drawerOpen ? {paddingLeft: `${drawerWidth}px)`} : {}}
                    key={banner} 
                    message={"Please verify your email by clicking on the emailed link"}
                    actionText={"Resend Email"}
                    action={() => {
                           axios.post(`${getDomain()}/api/v1/resend_email`)
                          .then((resp: any) => {
                            if (resp.data.status) {
                              setToastData({message: "Email Sent", type: "success"})
                            } else {
                              if (isAuthErrorResp(resp)) {
                                logout(props.history.push)
                              } else {
                                setToastData({message: resp.data.body, type: "error"});
                              }
                            }
                          })
                          .catch(() => window.location.reload());
                    }}
                    icon={<EmailIcon />}
                />
            )
        }
    }

    function handleMenu(event: any) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
                <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    {isAuthed() && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={() => setDrawerOpen(!drawerOpen)}
                            className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Cascara
                    </Typography>
                    {isAuthed() && (
                        <div>
                            <IconButton
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => {handleClose(); props.history.push("/profile")}}>Profile</MenuItem>
                                <MenuItem onClick={() => {handleClose(); setBanners([]); logout(props.history.push)}}>Logout</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            {isAuthed() && (
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
                    }}
                    open={drawerOpen}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={() => setDrawerOpen(false)}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <div>
                        <ListItem button onClick={() => props.history.push("/console")}>
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Console" />
                        </ListItem>
                        <ListItem button onClick={() => props.history.push("/expiration")}>
                            <ListItemIcon>
                                <QueryBuilderIcon />
                            </ListItemIcon>
                            <ListItemText primary="Session Length" />
                        </ListItem>
                        <ListItem button onClick={() => props.history.push("/audit_logs")}>
                            <ListItemIcon>
                                <FolderIcon />
                            </ListItemIcon>
                            <ListItemText primary="Audit Logs" />
                        </ListItem>
                        <ListItem button onClick={() => props.history.push("/level_up")}>
                            <ListItemIcon>
                                <ArrowUpwardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Level Up" />
                        </ListItem>
                    </div>
                    <Divider />
                </Drawer>
            )}
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {banners.map((bannerName)  => renderBanner(bannerName))}
                <Container maxWidth="lg" className={classes.container}>
                    {props.children}
                </Container>
            </main>
            <ToastMessage type={toastData.type} isOpen={toastData.message !== ""} message={toastData.message} />
        </div>
    );
}

