import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {withRouter} from "react-router";
import {ToastData, default as ToastMessage} from "../toastmessage/toastMessage";
import {isAuthErrorResp, isLoggedIn, logout} from "../../lib/sessionutils";
import {getDomain} from "../../lib/net";
import HistoryProps from "../../types/history";
import {Divider} from "@material-ui/core";
const axios = require("axios");

const useStyles = makeStyles(theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
}));

export default withRouter(Profile)
function Profile(props: {history: HistoryProps, match: {params: {token: string}}}) {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h4" align="center">
            Password Reset
          </Typography>
          <Typography variant="h6" align="center">
            Please contact <a href="mailto:security@cascarasecurity.com">security@cascarasecurity.com</a> for assistance in resetting your password
          </Typography>
        </Paper>
      </main>
    </>
  );
}
