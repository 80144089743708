import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import ToastMessage, {ToastData} from "../toastmessage/toastMessage";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {withRouter} from "react-router";
import {isAuthErrorResp, logout} from "../../lib/sessionutils";
import HistoryProps from "../../types/history";
import {getDomain} from "../../lib/net";
import {Divider} from "@material-ui/core";

const axios = require("axios");

const useStyles = makeStyles(theme => ({
    explanation: {
        paddingTop: "40px",
    },
}))

export default withRouter(SimpleInstallSSHKey as any)

function SimpleInstallSSHKey(props: { history: HistoryProps }) {
    const classes = useStyles();

    const [caKey, setCaKey] = React.useState<string | null>(null);
    const [toastData, setToastData] = useState<ToastData>({message: "", type: "error"});

    useEffect(() => {
        axios
            .get(`${getDomain()}/api/v1/get_ca_key`)
            .then((resp: any) => {
                if (resp.data.status) {
                    setCaKey(resp.data.body);
                } else {
                    if (isAuthErrorResp(resp)) {
                        logout(props.history.push)
                    } else {
                        setToastData({message: resp.data.body, type: "error"});
                    }
                }
            })
            .catch(() => window.location.reload());
    }, []);

    return (
        <>
            <Typography variant="h6" gutterBottom>
                Deploy one key to all your servers
            </Typography>
            <SyntaxHighlighter language="shell">
                {caKey
                    ? 'echo "' +
                    caKey +
                    '" > /etc/ssh/ca.pub\necho "TrustedUserCAKeys /etc/ssh/ca.pub" >> /etc/ssh/sshd_config\nservice ssh restart'
                    : "Loading..."}
            </SyntaxHighlighter>
            <Typography variant="body1" align="center" className={classes.explanation}>
                This one key is the certificate that will be used to grant SSH access. Our software will automatically
                provision short-lived SSH keys that are trusted by this certificate. This means that you will never again
                have to manage SSH keys!
            </Typography>

            <ToastMessage type={toastData.type} isOpen={toastData.message !== ""} message={toastData.message}/>
        </>
    );
}
