import React, {useState, useEffect, Component} from "react";
import {makeStyles} from "@material-ui/core/styles";
import ToastMessage, {ToastData} from "../toastmessage/toastMessage";
import {isAuthErrorResp, logout} from "../../lib/sessionutils";
import {withRouter} from "react-router";
import HistoryProps from "../../types/history";
import {getDomain} from "../../lib/net";
import SimpleManageUsers from "./SimpleManageUsers";
import AdvancedManageUsers from "./AdvancedManageUsers";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";

const axios = require("axios");

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0)
    },
    total: {
        fontWeight: 700
    },
    title: {
        marginTop: theme.spacing(2)
    },
    addEmailButton: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function OrgModeSwitcher(props: { history: HistoryProps,  simple_component: any, advanced_component: any}) {
    const classes = useStyles();
    const [orgMode, setOrgMode] = useState<string>("");
    const [toastData, setToastData] = useState<ToastData>({message: "", type: "error"});

    useEffect(() => {
        axios.get(`${getDomain()}/api/v1/get_org_mode`)
            .then((resp: any) => {
                if (resp.data.status) {
                    setOrgMode(resp.data.body)
                } else {
                    if (isAuthErrorResp(resp)) {
                        logout(props.history.push)
                    } else {
                        setToastData({message: resp.data.body, type: "error"});
                    }
                }
            })
            .catch(() => window.location.reload());
    }, []);

    return (
        <>
            {orgMode === "SIMPLE" && props.simple_component}
            {orgMode == "ADVANCED" && props.advanced_component}
            {orgMode == "" && <LinearProgress /> }
            <ToastMessage type={toastData.type} isOpen={toastData.message !== ""} message={toastData.message}
                          closed={() => setToastData((prev) => ({message: "", type: prev.type}))}/>
        </>
    );
}
