import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import {withRouter} from "react-router";
import {ToastData, default as ToastMessage} from "../toastmessage/toastMessage";
import {isAuthErrorResp, logout} from "../../lib/sessionutils";
import {getDomain} from "../../lib/net";
import HistoryProps from "../../types/history";
const axios = require("axios");

const useStyles = makeStyles(theme => ({
    layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3)
        },
        overflowX: "auto"
    },
    table: {
        minWidth: '80%',
    },
}));

type Log = any

export default withRouter(AuditLogs)
function AuditLogs(props: {history: HistoryProps, match: {params: {token: string}}}) {
    const classes = useStyles();

    const [logs, setLogs] = useState<Array<Log> | null>(null);
    const [toastData, setToastData] = useState<ToastData>({message: "", type: "error"});

    useEffect(() => {
        axios
            .get(`${getDomain()}/api/v1/get_logs?type=text&limit=50`)
            .then((resp: any) => {
                if (resp.data.status) {
                    setLogs(resp.data.body)
                } else {
                    if (isAuthErrorResp(resp)) {
                        logout(props.history.push)
                    } else {
                        setToastData({message: resp.data.body, type: "error"});
                    }
                }
            })
            .catch(() => window.location.reload());
    }, []);

    return (
        <>
            <CssBaseline />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center">
                        Audit Logs
                    </Typography>
                    <Grid justify="center" container spacing={10} style={{padding: "10px"}}>
                        <Grid item>
                            <form method="get" action={`${getDomain()}/api/v1/get_logs`}>
                                <input type="hidden" name="download" value="true" />
                                <input type="hidden" name="type" value="text" />

                                <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >Download plaintext logs</Button>
                            </form>
                        </Grid>
                        <Grid item>
                            <form method="get" action={`${getDomain()}/api/v1/get_logs`}>
                                <input type="hidden" name="download" value="true" />
                                <input type="hidden" name="type" value="json" />

                                <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >Download JSON logs</Button>
                            </form>
                        </Grid>
                    </Grid>

                        <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>IP Address</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Log Line</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logs ? logs.map(log => (
                                <TableRow key={JSON.stringify(log)}>
                                    <TableCell component="th" scope="row">
                                        {log[0]}
                                    </TableCell>
                                    <TableCell>{log[1]}</TableCell>
                                    <TableCell>{log[2]}</TableCell>
                                </TableRow>
                            )) : (
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Loading...
                                    </TableCell>
                                    <TableCell align="right">Loading...</TableCell>
                                    <TableCell align="right">Loading...</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Paper>
            </main>
            <ToastMessage autoClose={true} type={toastData.type} isOpen={toastData.message !== ""} message={toastData.message} />
        </>
    );
}
