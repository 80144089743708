import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {withRouter} from "react-router";
import {ToastData, default as ToastMessage} from "../toastmessage/toastMessage";
import {isAuthErrorResp, isLoggedIn, logout} from "../../lib/sessionutils";
import ExpirationPicker from "../expirationpicker/expirationpicker";
import HistoryProps from "../../types/history";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {InputLabel} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Box from "@material-ui/core/Box";
import {green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button/Button";
import {getDomain} from "../../lib/net";

const axios = require("axios");

const useStyles = makeStyles(theme => ({
    layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3)
        }
    },
    nestedPaper: {
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            padding: theme.spacing(3)
        },
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    flexGrow: {
        flexGrow: 1
    }
}));

export default withRouter(LevelUp)

function LevelUp(props: { history: HistoryProps }) {
    const classes = useStyles();

    const [toastData, setToastData] = useState<ToastData>({message: "", type: "error"});

    return (
        <>
            <CssBaseline/>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography variant="h4" align="center">
                        Level Up Your Security
                    </Typography>
                    <div style={{paddingBottom: '50px'}}>
                        <Grid container justify="center" spacing={5}>
                            <Grid item xs={6}>
                                <Paper className={classes.nestedPaper}>
                                    <Typography variant="h6" align="center">
                                        2FA for SSH
                                    </Typography>
                                    <Typography variant="subtitle2" align="center">
                                        Configure OpenSSH to require 2FA for all SSH connections
                                    </Typography>
                                    <br/>
                                    <div className={classes.flexGrow}/>
                                    <Box borderRadius={16} borderColor={green[800]} border={2}>
                                        <Typography align="center">
                                            Coming Soon
                                        </Typography>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.nestedPaper}>
                                    <Typography variant="h6" align="center">
                                        Deploy a Bastion
                                    </Typography>
                                    <Typography variant="subtitle2" align="center">
                                        Deploy a special server as a jump box in order to isolate your servers from the
                                        internet
                                    </Typography>
                                    <br/>
                                    <div className={classes.flexGrow}/>

                                    <Box borderRadius={16} borderColor={green[800]} border={2}>
                                        <Typography align="center">
                                            Coming Soon
                                        </Typography>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.nestedPaper}>
                                    <Typography variant="h6" align="center">
                                        Create Realms
                                    </Typography>
                                    <Typography variant="subtitle2" align="center">
                                        Define realms of servers (such as production or staging) and grant limited
                                        access
                                        to cssh users based off of the permissions they need
                                    </Typography>
                                    <br/>
                                    <div className={classes.flexGrow}/>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            axios.post(`${getDomain()}/api/v1/set_org_mode`, {org_mode: 'ADVANCED'})
                                                .then((resp: any) => {
                                                    if (resp.data.status) {
                                                        props.history.push("/console")
                                                    } else {
                                                        if (isAuthErrorResp(resp)) {
                                                            logout(props.history.push)
                                                        } else {
                                                            setToastData({message: resp.data.body, type: "error"});
                                                        }
                                                    }
                                                })
                                                .catch(() => window.location.reload());
                                        }}
                                    >
                                        <Typography variant="subtitle1" align="center">
                                            Enable
                                        </Typography>
                                    </Button>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
                <ToastMessage type={toastData.type} isOpen={toastData.message !== ""} message={toastData.message}
                              closed={() => setToastData((prev) => ({message: "", type: prev.type}))}/>

            </main>
        </>
    )
        ;
}
