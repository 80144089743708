import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {withRouter} from "react-router";
import {ToastData, default as ToastMessage} from "../toastmessage/toastMessage";
import {isAuthErrorResp, isLoggedIn, logout} from "../../lib/sessionutils";
import ExpirationPicker from "../expirationpicker/expirationpicker";
import HistoryProps from "../../types/history";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {InputLabel} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Box from "@material-ui/core/Box";
import {green} from "@material-ui/core/colors";
import {isValidEmail} from "../../lib/validation";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Button from "@material-ui/core/Button/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HelpIcon from "@material-ui/icons/Help";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider/Divider";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import {getDomain} from "../../lib/net";

const axios = require("axios");


const useStyles = makeStyles(theme => ({
    layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3)
        }
    },
    addItemButton: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

// TODO(advanced): Yell at them if they try to save and there are no realms or no one has access to realms

export default withRouter(AdvancedManageUsers)

function AdvancedManageUsers(props: { history: HistoryProps }) {
    const classes = useStyles();

    const [principals, setPrincipals] = React.useState<Array<string>>([""]);
    const [userToPrincipals, setUserToPrincipals] = React.useState<Array<[string, Array<string>]>>([["", []]])
    const [sshUser, setSshUser] = React.useState<string>("")
    const [successCount, setSuccessCount] = useState<number>(0);
    const [toastData, setToastData] = useState<ToastData>({message: "", type: "error"});

    useEffect(() => {
        axios.get(`${getDomain()}/api/v1/get_org_mode`)
            .then((resp: any) => {
                if (resp.data.status) {
                    if (resp.data.body !== "ADVANCED") {
                        props.history.push("/console")
                    }
                } else {
                    if (isAuthErrorResp(resp)) {
                        logout(props.history.push)
                    } else {
                        setToastData({message: resp.data.body, type: "error"});
                    }
                }
            })
            .catch(() => window.location.reload());
        axios.get(`${getDomain()}/api/v1/advanced_get_principals`)
            .then((resp: any) => {
                if (resp.data.status) {
                    if (resp.data.body.length == 0) {
                        setPrincipals([""]);
                    } else {
                        setPrincipals(resp.data.body)
                    }
                } else {
                    if (isAuthErrorResp(resp)) {
                        logout(props.history.push)
                    } else {
                        setToastData({message: resp.data.body, type: "error"});
                    }
                }
            })
            .catch(() => window.location.reload());
        axios.get(`${getDomain()}/api/v1/advanced_get_emails`)
            .then((resp: any) => {
                if (resp.data.status) {
                    let data: Array<[string, Array<string>]> = Object.entries(resp.data.body);
                    if (data.length == 0) {
                        setUserToPrincipals([["", []]])
                    } else {
                        setUserToPrincipals(data);
                    }
                } else {
                    if (isAuthErrorResp(resp)) {
                        logout(props.history.push)
                    } else {
                        setToastData({message: resp.data.body, type: "error"});
                    }
                }
            })
            .catch(() => window.location.reload());
        axios.get(`${getDomain()}/api/v1/advanced_get_ssh_user`)
            .then((resp: any) => {
                if (resp.data.status) {
                    setSshUser(resp.data.body)
                } else {
                    if (isAuthErrorResp(resp)) {
                        logout(props.history.push)
                    } else {
                        setToastData({message: resp.data.body, type: "error"});
                    }
                }
            })
            .catch(() => window.location.reload());
    }, []);

    if (successCount >= 2) {
        setSuccessCount(prev => prev >= 2 ? prev - 2 : prev)
        setToastData({message: "Saved!", type: "success"})
    }

    useEffect(() => {
        let cleanedUserToPrincipals: Array<[string, Array<string>]> = userToPrincipals.map(([user, allowed_principals]) =>
            [user, allowed_principals.filter(p => principals.includes(p))]
        )
        if (JSON.stringify(cleanedUserToPrincipals) !== JSON.stringify(userToPrincipals)) {
            setUserToPrincipals(cleanedUserToPrincipals);
        }
    }, [userToPrincipals, principals])

    return (
        <>
            <Typography variant="h6" gutterBottom>
                Manage Users
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
                Realms {"  "}
                <Tooltip
                    arrow
                    title="Define the different realms of SSH servers (eg 'prod', 'staging', 'ci')">
                    <HelpIcon/>
                </Tooltip>
            </Typography>
            <List dense={false}>
                {principals.map((principal, idx) => (
                    <ListItem key={principal + idx}>
                        <ListItemAvatar>
                            <Avatar>
                                <FolderIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required={idx === 0}
                            fullWidth
                            id="realm"
                            label="Realm Name"
                            autoFocus
                            name="realm"
                            error={principal === ""}
                            value={principal}
                            onChange={event => {
                                setPrincipals(
                                    principals.map((p, i) => (i === idx ? event.target.value : p))
                                );
                            }}
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="Delete"
                                onClick={() => {
                                    let updated_principals = principals.filter((_, i) => i !== idx);
                                    setPrincipals(updated_principals);
                                    setUserToPrincipals(userToPrincipals.map(([user, allowed_principals]) =>
                                        [user, allowed_principals.filter(p => updated_principals.includes(p))]
                                    ))
                                }}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <Button
                className={classes.addItemButton}
                onClick={() => setPrincipals([...principals, ""])}
            >
                <AddCircleIcon/>
            </Button>
            <Divider/>
            <Typography variant="subtitle1" gutterBottom>
                Cascara Users {"  "}
                <Tooltip
                    arrow
                    title="Configure the GSuite users that will be given SSH access via logging in with their Google account and which realms of servers they should have access to">
                    <HelpIcon/>
                </Tooltip>
            </Typography>
            <List dense={false}>
                {userToPrincipals.map(([email, selectedPrincipals], idx: number) => (
                    <ListItem key={email + idx}>
                        <ListItemAvatar>
                            <Avatar>
                                <FolderIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required={idx === 0}
                            fullWidth
                            id="email"
                            label={isValidEmail(email) || email === "" ? "Email Address" : "Please enter a valid email address"}
                            name="email"
                            error={email !== "" && !isValidEmail(email)}
                            value={email}
                            onChange={event =>
                                setUserToPrincipals(
                                    userToPrincipals.map((up, i) => (i === idx ? [event.target.value, up[1]] : up))
                                )
                            }
                        />
                        {principals.map((principal, i) => (
                            <FormControlLabel
                                value="top"
                                key={principal + i}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={selectedPrincipals.includes(principal)}
                                        onChange={event =>
                                            setUserToPrincipals(
                                                userToPrincipals.map((up, i) => (
                                                    i === idx ?
                                                        [
                                                            up[0],
                                                            event.target.checked ? [...up[1], principal] : up[1].filter(p => p !== principal)
                                                        ]
                                                        :
                                                        up)
                                                )
                                            )
                                        }
                                    />
                                }
                                label={principal === "" ? `Realm#${i}` : principal}
                                labelPlacement="top"
                            />
                        ))}
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="Delete"
                                onClick={() => setUserToPrincipals(userToPrincipals.filter((_, i) => i !== idx))}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
                <Button
                    className={classes.addItemButton}
                    onClick={() => (setUserToPrincipals([...userToPrincipals, ["", []]]))}
                >
                    <AddCircleIcon/>
                </Button>
            </List>
            <Divider/>
            <Typography variant="subtitle1" gutterBottom>
                SSH Username {"  "}
                <Tooltip
                    arrow
                    title="The SSH user used on all servers (root by default)">
                    <HelpIcon/>
                </Tooltip>
            </Typography>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="principal"
                label="SSH User"
                name="principal"
                value={sshUser}
                onChange={event => setSshUser(event.target.value)}
            />
            <Divider/>
            <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => {
                    axios
                        .post(`${getDomain()}/api/v1/advanced_set_ssh_user`, {username: sshUser})
                        .then(function (response: any) {
                            if (response.data.status) {
                                setSuccessCount(successCount => successCount + 1)
                            }
                        })
                        .catch((error: any) => {
                            console.log(error);
                            window.location.reload();
                        })
                    axios
                        .post(`${getDomain()}/api/v1/advanced_set_principals`, {principals})
                        .then(function (response: any) {
                            if (response.data.status) {
                                setSuccessCount(successCount => successCount + 1)
                                axios
                                    .post(`${getDomain()}/api/v1/advanced_set_emails`, Object.fromEntries(userToPrincipals))
                                    .then((response: any) => {
                                        if (response.data.status) {
                                            setSuccessCount(successCount => successCount + 1)
                                        } else {
                                            if (isAuthErrorResp(response)) {
                                                logout(props.history.push)
                                            } else {
                                                setToastData({message: response.data.body, type: "error"});
                                            }
                                        }
                                    })
                                    .catch((error: any) => {
                                        console.log(error);
                                        window.location.reload();
                                    });

                            } else {
                                if (isAuthErrorResp(response)) {
                                    logout(props.history.push)
                                } else {
                                    setToastData({message: response.data.body, type: "error"});
                                }
                            }
                        })
                        .catch(function (error: any) {
                            console.log(error);
                            window.location.reload();
                        });
                }}
            >
                Save
            </Button>
            <ToastMessage type={toastData.type} isOpen={toastData.message !== ""} message={toastData.message}
                          closed={() => setToastData((prev) => ({message: "", type: prev.type}))}/>
        </>
    );
}
