import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {withRouter} from "react-router";
import {ToastData, default as ToastMessage} from "../toastmessage/toastMessage";
import {isAuthErrorResp, isLoggedIn, logout} from "../../lib/sessionutils";
import ExpirationPicker from "../expirationpicker/expirationpicker";
import HistoryProps from "../../types/history";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {InputLabel} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles(theme => ({
    layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3)
        }
    },
    padding: {
        padding: "10px"
    },
    flex: {
        display: "flex",
    },
}));

export default withRouter(Expiration)
function Expiration(props: {history: HistoryProps}) {
    const classes = useStyles();

    const [tab, setTab] = useState<number>(0);

    return (
        <>
            <CssBaseline />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center">
                        Session Length
                    </Typography>
                    <Tabs
                        value={tab}
                        onChange={(_, val) => setTab(val)}
                        variant="fullWidth"
                        indicatorColor="secondary"
                        textColor="secondary"
                    >
                        <Tab icon={<VpnKeyIcon />} label="Key" />
                        <Tab icon={<AccountCircle />} label="User" />
                    </Tabs>
                    {tab == 0 ? (
                        <ExpirationPicker
                            key="key"
                            description="This is how often the temporary SSH keys generated by cssh are valid for. It is recommended to keep this a short time period."
                            getEndpoint="/api/v1/get_key_expiration"
                            setEndpoint="/api/v1/set_key_expiration"
                            initExpiration={{number: 1, unit: "h"}}
                        />
                    ) : (
                        <ExpirationPicker
                            key="oauth"
                            description="This is how often users must re-login via Google. It is recommended to keep this a short time period."
                            getEndpoint="/api/v1/get_oauth_expiration"
                            setEndpoint="/api/v1/set_oauth_expiration"
                            initExpiration={{number: 1, unit: "d"}}
                        />
                    )}
                </Paper>
            </main>
        </>
    );
}
