import React from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

type Props = {
  message: string
  icon: React.ReactNode
  actionText?: string
  action?: () => void
  style?: any
}

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    backgroundColor: "rgb(0,0,0,0.1)"
  },
  avatar: {
  }
}));

function Banner(props: Props) {
  const classes = useStyles();
  return (
    <>
      <Paper elevation={0} className={classes.paper} style={props.style ? props.style : {}}>
        <Grid container wrap="nowrap" alignItems="center" justify="space-between">
          <Grid item>
            <Avatar className={classes.avatar}>
              {props.icon}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography>
              {props.message}
            </Typography>
          </Grid>
                  {props.action && props.actionText && (
          <Grid item>
            <Button color="primary" onClick={props.action}>{props.actionText}</Button>
          </Grid>
          )}
        </Grid>
      </Paper>
      <CssBaseline />
    </>
  );
}

export default Banner;
