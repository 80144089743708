import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import SignIn from "./pages/signin/signin";
import SignUp from "./pages/signup/signup";
import MenuBar from "./pages/menubar/menubar";
import Tutorial from "./pages/tutorial/tutorial";
import Profile from "./pages/profile/profile";
import VerifyEmail from "./pages/verifyemail/verifyemail";
import ResetPassword from "./pages/resetpassword/resetpassword";
import AuditLogs from "./pages/auditlogs/auditlogs";
import Expiration from "./pages/expiration/expiration";
import LevelUp from "./pages/levelup/levelup";
import AdvancedConfig from "./pages/tutorial/AdvancedManageUsers";
import {withTracker} from "./lib/googleanalytics";
const axios = require("axios");

function MyRouter() {
  axios.defaults.withCredentials = true;
  return (
    <Router>
      <div>
        <MenuBar >
          <Route exact path="/" component={withTracker(Landing)} />
          <Route path="/signin" component={withTracker(SignIn)} />
          <Route path="/signup" component={withTracker(SignUp)} />
          <Route path="/console" component={withTracker(Tutorial)} />
          <Route path="/profile" component={withTracker(Profile)} />
          <Route path="/verify_email/:token" component={withTracker(VerifyEmail)} />
          <Route path="/reset_password" component={withTracker(ResetPassword)} />
          <Route path="/audit_logs" component={withTracker(AuditLogs)} />
          <Route path="/expiration" component={withTracker(Expiration)} />
          <Route path="/level_up" component={withTracker(LevelUp)} />
        </MenuBar>
      </div>
    </Router>
  );
}

function Landing() {
  return <Redirect to="/signin" />;
}

export default MyRouter;
